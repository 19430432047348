import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import truncateContent from "../utils/truncate-content"

export default ({ limit, render }) => {
  const gql = graphql`
    query {
      blogPosts: allFeedrssBlog(limit: 3) {
        nodes {
          title
          link
          content
          creator
        }
      }
    }
  `
  const nodes = useStaticQuery(gql).blogPosts.nodes.map((node) => ({
    ...node,
    content: truncateContent(node.content, 250),
  }))

  return <>{render(nodes || [])}</>
}
