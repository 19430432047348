exports.listenForIncantation = (word, cb) => {
  let matched = 0
  const onKeyPress = (e) => {
    if (word.charAt(matched) === String.fromCharCode(e.which)) {
      matched++
    } else {
      matched = 0
    }
    if (matched === word.length) {
      cb(e, word)
      matched = 0
    }
  }
  window.addEventListener("keypress", onKeyPress, true)
}
