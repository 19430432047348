import { H5 } from "@fastly/consistently"
import React from "react"
import Link from "~components/link/link"

import * as styles from "./solutions-table.module.css"

const SOLUTION_TYPES = {
  demos: {
    label: "Demos",
    summary: <>Sites running on Fastly you can browse. See the principles working for an end user.</>,
  },
  tutorials: {
    label: "Tutorials",
    summary: <>Step by step instructions. Build and learn.</>,
  },
  starters: {
    label: "Starter kits",
    summary: (
      <>
        Template <Link to="/guides/compute">Compute</Link> applications you can use to bootstrap your project.
      </>
    ),
  },
  examples: {
    label: "Code examples",
    summary: <>Snippets of code ready to copy and paste.</>,
  },
}

const SolutionsTable = ({ nodes }) => {
  nodes.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
  return (
    <>
      <p>
        The following <Link to="/solutions/demos">demos</Link>, <Link to="/solutions/tutorials">tutorials</Link>,{" "}
        <Link to="/solutions/starters">starter kits</Link>, and <Link to="/solutions/examples">code examples</Link> are
        relevant to this use case. Try them out!
      </p>
      {Object.entries(SOLUTION_TYPES)
        .filter(([k]) => nodes.some((n) => n.type === k))
        .map(([typeKey, type]) => (
          <div key={typeKey}>
            <H5 as="h3">{type.label}</H5>
            <p>{type.summary}</p>
            <div className={`tableWrapper ${styles.solutionsTable}`}>
              <table>
                <tbody>
                  {nodes
                    .filter((n) => n.type === typeKey)
                    .map((node) => (
                      <tr key={typeKey + node.path}>
                        <td>
                          <Link to={node.path}>{node.title}</Link>
                        </td>
                        <td>{node.description || node.summary}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </>
  )
}

export default SolutionsTable
