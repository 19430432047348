import React, { useState, useRef, useEffect, useCallback } from "react"
import ReactDOM from "react-dom"
import { Overlay } from "react-overlays"
import isClientSide from "~utils/is-client-side"

import * as styles from "./tooltip.module.css"

const getDwellDelay = (evtType) => (evtType === "mouseover" ? 200 : 500)

const matches = (candidate, target) =>
  (target instanceof Element && candidate === target) ||
  (typeof target === "string" && candidate instanceof Element && candidate.matches(target))

const ToolTip = (props) => {
  const currentTargetEl = useRef(null)
  const [visible, setVisible] = useState()
  const [content, setContent] = useState(null)
  const delayTimer = useRef(null)

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const onHideIntent = () => {
    if (props.trigger === "hover") setVisible(false)
    if (delayTimer.current) clearTimeout(delayTimer.current)
    props.onHideIntent && props.onHideIntent()
  }

  const handleTrigger = useCallback(
    (evt) => {
      ReactDOM.unstable_batchedUpdates(() => {
        let newVisibleState
        if (evt.type === "mouseover") {
          const targetEl = evt.composedPath().find((el) => matches(el, props.target))
          if (targetEl instanceof Element) {
            newVisibleState = true
            currentTargetEl.current = targetEl
            setContent(props.contentAttr ? targetEl.getAttribute(props.contentAttr) : null)
            forceUpdate()
          }
        } else if (
          evt.target &&
          (matches(evt.target, props.target) || !evt.composedPath().some((el) => matches(el, props.target)))
        ) {
          newVisibleState = false
        }
        if (newVisibleState !== undefined) {
          if (delayTimer.current) clearTimeout(delayTimer.current)
          if (newVisibleState !== visible) {
            delayTimer.current = setTimeout(() => {
              setVisible(newVisibleState)
            }, getDwellDelay(evt.type))
          }
        }
      })
    },
    [props.target, props.contentAttr, visible, delayTimer, forceUpdate]
  )

  useEffect(() => {
    const listenRoot = props.target instanceof Element ? props.target : document.body
    if (listenRoot && props.trigger === "hover") {
      listenRoot.addEventListener("mouseover", handleTrigger)
      listenRoot.addEventListener("mouseout", handleTrigger)
    }
    return () => {
      if (listenRoot) {
        listenRoot.removeEventListener("mouseover", handleTrigger)
        listenRoot.removeEventListener("mouseout", handleTrigger)
      }
    }
  }, [props.target, props.trigger, handleTrigger])

  const isVisible = visible !== undefined ? visible : props.visible || false
  const target = currentTargetEl.current || props.target

  if (!isClientSide() || !(target instanceof Element)) return null

  return (
    <Overlay
      show={isVisible}
      onHide={onHideIntent}
      placement={props.placement || "bottom"}
      target={target}
      container={document.body}
      popperConfig={{
        modifiers: {
          offset: { options: { offset: [0, 4] } },
          flip: { options: {} },
        },
      }}
      rootClose
    >
      {({ props: tipProps, placement }) => (
        <div {...tipProps} className={`${styles.tooltip} ${styles[placement]} ${styles[props.type]}`}>
          <div className={styles.arrow} />
          <div className={styles.inner}>{content || props.children}</div>
        </div>
      )}
    </Overlay>
  )
}

export default ToolTip
