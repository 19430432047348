import { withPrefix } from "gatsby"

export default {
  clientIP: {
    description: "Client IP address",
    vcl: "req.http.fastly-client-ip",
    edgeCompute: true,
    starred: true,
  },
  ispID: {
    description: "Client ISP ID (autonomous system number)",
    vcl: "client.as.number",
  },
  ispName: {
    description: "Client ISP name (the name of the organization associated with the client's autonomous system)",
    vcl: "client.as.name",
  },
  countryCode: {
    description: "Country from which client request originated",
    vcl: "client.geo.country_code",
    edgeCompute: true,
    starred: true,
  },
  connectionType: {
    description: "Client connection type",
    vcl: "client.geo.conn_type",
    edgeCompute: true,
  },
  lat: {
    description: "Approximate client latitude",
    type: "number",
    vcl: "client.geo.latitude",
    edgeCompute: true,
  },
  long: {
    description: "Approximate client longitude",
    type: "number",
    vcl: "client.geo.longitude",
    edgeCompute: true,
  },
  tlsVersion: {
    description: "TLS version",
    vcl: "tls.client.protocol",
    starred: true,
  },
  sniServerName: {
    description: "SNI server name",
    vcl: "tls.client.servername",
  },
  tlsCipher: {
    description: "Cipher used in TLS request",
    vcl: "tls.client.cipher",
  },
  tlsCipherSuiteIDs: {
    description: "SHA-1 of cipher suite identifiers",
    vcl: "tls.client.ciphers_sha",
  },
  tlsExtensionIDs: {
    description: "SHA-1 of TLS extension identifiers",
    vcl: "tls.client.tlsexts_sha",
  },
  congestionAlgorithm: {
    description: "Congestion control algorithm",
    vcl: "client.socket.congestion_algorithm",
  },
  congestionWindow: {
    description: "Current congestion window size (packets)",
    vcl: "client.socket.cwnd",
  },
  bufferSpace: {
    description: "Buffer space available for receiving data",
    vcl: "client.socket.tcpi_rcv_space",
  },
  requests: {
    description: "HTTP requests received on this connection (including the current one)",
    vcl: "client.requests",
  },
  rtt: {
    description: "Round-trip time",
    vcl: "client.socket.tcpi_rtt",
  },
  rttVar: {
    description: "Round-trip time variance",
    vcl: "client.socket.tcpi_rttvar",
  },
  rcpRtt: {
    description: "Receiver-side estimate of round-trip time",
    vcl: "client.socket.tcpi_rcv_rtt",
  },
  maxSize: {
    description: "Socket max segment size (receive)",
    vcl: "client.socket.tcpi_rcv_mss",
  },
  isIPv6: {
    description: "Whether this is an IPv6 request",
    vcl: "req.is_ipv6",
  },
  isH2: {
    description: "Whether client request is HTTP/2",
    vcl: "fastly_info.is_h2",
    starred: true,
  },
  isH2Push: {
    description: "Whether this is a generated H2 Push request",
    vcl: "fastly_info.h2.is_push",
  },
  h2StreamId: {
    description: "HTTP/2 stream ID",
    vcl: "fastly_info.h2.stream_id",
  },
  vclName: {
    description: "Name of the service VCL currently loaded",
    vcl: "req.vcl",
  },
  vclVersion: {
    description: "Version of the VCL currently executing",
    vcl: "req.vcl.version",
    starred: true,
  },
  popId: {
    description: "Fastly POP identifier",
    vcl: "server.datacenter",
    edgeCompute: true,
    starred: true,
  },
  cacheId: {
    description: "Cache node identifier",
    vcl: "server.hostname",
    edgeCompute: true,
  },
  isShield: {
    description: "Whether the request is being handled on a shield POP",
    vcl: "fastly.ff.visits_this_service",
  },
  reqHeaderBytes: {
    description: "Size of client request headers",
    vcl: "req.header_bytes_read",
  },
  reqBodyBytes: {
    description: "Size of client request body",
    vcl: "req.body_bytes_read",
  },
  urlPath: {
    description: "URL path",
    vcl: "req.url.path",
    edgeCompute: true,
    starred: true,
  },
  httpReferer: {
    description: "HTTP <code>Referer</code> header received in request",
    vcl: "req.http.referer",
    edgeCompute: true,
  },
  httpUA: {
    description: "HTTP <code>User-Agent</code> header received in request",
    vcl: "req.http.user-agent",
    edgeCompute: true,
  },
  httpAccept: {
    description: `HTTP <a href="${withPrefix(
      `/reference/http/http-headers/Accept/`
    )}"><code>Accept</code></a> header received in request`,
    vcl: "req.http.accept",
    edgeCompute: true,
  },
  httpAcceptLanguage: {
    description: `HTTP <a href="${withPrefix(
      `/reference/http/http-headers/Accept-Language/`
    )}"><code>Accept-Language</code></a> header received in request`,
    vcl: "req.http.accept-language",
    edgeCompute: true,
  },
  httpAcceptCharset: {
    description: "HTTP <code>Accept-Charset</code> header received in request",
    vcl: "req.http.accept-charset",
    edgeCompute: true,
  },
  httpIfModifiedSince: {
    description: "HTTP <code>If-Modified-Since</code> header received in request",
    vcl: "req.http.if-modified-since",
    edgeCompute: true,
  },
  httpIfNoneMatch: {
    description: "HTTP <code>If-None-Match</code> header received in request",
    vcl: "req.http.if-none-match",
    edgeCompute: true,
  },
  cacheState: {
    description: "Category of cache result",
    vcl: "fastly-info.state",
    starred: true,
  },
  objAge: {
    description: "Age of cached object",
    vcl: "obj.age",
    starred: true,
  },
  objHits: {
    description: "The number of times this object has been used on this cache server",
    vcl: "obj.hits",
  },
  objTTL: {
    description: "Cache freshness lifetime remaining",
    vcl: "obj.ttl",
  },
  respStatus: {
    description: "HTTP response status code",
    vcl: "resp.status",
  },
  respStatusText: {
    description: "HTTP status text of response",
    vcl: "resp.response",
  },
  httpCacheControl: {
    description: `HTTP <a href="${withPrefix(
      `/reference/http/http-headers/Cache-Control/`
    )}"><code>Cache-Control</code></a> header sent in response`,
    vcl: "resp.http.cache-control",
  },
  httpContentLength: {
    description: `HTTP <a href="${withPrefix(
      `/reference/http/http-headers/Content-Length/`
    )}"><code>Content-Length</code></a> header sent in response`,
    vcl: "resp.completed",
    edgeCompute: true,
    starred: true,
  },
  httpContentType: {
    description: `HTTP <code>Content-Type</code> header sent in response`,
    vcl: "resp.http.content-type",
    edgeCompute: true,
    starred: true,
  },
  httpExpires: {
    description: `HTTP <a href="${withPrefix(
      `/reference/http/http-headers/Expires/`
    )}"><code>Expires</code></a> header sent in response`,
    vcl: "resp.http.expires",
    edgeCompute: true,
  },
  httpLastModified: {
    description: "HTTP <code>Last-Modified</code> header sent in response",
    vcl: "resp.http.last-modified",
    edgeCompute: true,
  },
  httpETag: {
    description: "HTTP <code>ETag</code> header sent in response",
    vcl: "resp.http.etag",
    edgeCompute: true,
  },
  respHeaderBytes: {
    description: "Bytes delivered as the response header",
    vcl: "resp.header_bytes_written",
  },
  respBodyBytes: {
    description: "Bytes delivered as the response body",
    vcl: "resp.body_bytes_written",
  },
  respTotalBytes: {
    description: "Total bytes delivered to client",
    vcl: "resp.bytes_written",
  },
  respCompleted: {
    description: "Whether the response has been completed",
    vcl: "resp.completed",
  },
  bitrate: {
    description: "Bitrate from Fastly to client",
    vcl: "client.socket.tcpi_delivery_rate",
  },
  packetLoss: {
    description: "Estimated packet loss",
    vcl: "client.socket.ploss",
  },
  packetRetransmissionCount: {
    description: "Packet retransmission count",
    vcl: "client.socket.tcpi_delta_retrans",
  },
  reqStartTime: {
    description: "Start time of request in ISO8601 format",
    vcl: `strftime({"%Y-%m-%dT%H:%M:%S%z"}, time.start)`,
    starred: true,
  },
  reqStartUnix: {
    description: "Start time of request as a unix timestamp",
    vcl: `time.start.sec`,
  },
  reqEndTime: {
    description: "End time of request in ISO8601 format",
    vcl: `strftime({"%Y-%m-%dT%H:%M:%S%z"}, time.end)`,
  },
  timeElapsedSocket: {
    description: "Time elapsed since data last sent to client",
    vcl: "client.socket.tcpi_last_data_sent",
  },
  timeElapsed: {
    description: "Elapsed time",
    vcl: "time.elapsed.usec",
    starred: true,
  },
}
