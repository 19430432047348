import React from "react"
import clsx from "clsx"
import slugify from "slugify"
import Link from "~components/link/link"
import * as styles from "./label-list.module.css"

export default ({ labels, labelOverrides, linkPrefix, float, background, small: isSmall, textList }) => {
  return (
    <span
      className={clsx(styles.labelList, {
        [styles.withBackground]: Boolean(background),
        [styles.small]: Boolean(isSmall),
        [styles.floatRight]: Boolean(float === "right"),
        [styles.textList]: Boolean(textList),
      })}
    >
      {[...new Set(labels)]
        .filter((l) => !!l)
        .map((l, idx) => {
          const lowerCaseLabel = l.toLowerCase()
          const label = labelOverrides && labelOverrides[lowerCaseLabel] ? labelOverrides[lowerCaseLabel] : l
          return linkPrefix ? (
            <Link key={`${l}${idx}`} to={`${linkPrefix}${slugify(lowerCaseLabel)}`}>
              {label}
            </Link>
          ) : (
            <span key={`${l}${idx}`}>{label}</span>
          )
        })}
    </span>
  )
}
