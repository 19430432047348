import React from "react"

import { Provider as CodeExampleNodes } from "~providers/code-examples"
import { Provider as StarterKitNodes } from "~providers/starters"
import { Provider as DemoNodes } from "~providers/demos"
import { Provider as TutorialNodes } from "~providers/tutorials"

const SOLUTION_NODES = {
  examples: CodeExampleNodes,
  demos: DemoNodes,
  starters: StarterKitNodes,
  tutorials: TutorialNodes,
}

const SOLUTION_TYPES = Object.keys(SOLUTION_NODES)

export default ({ render, type, types = SOLUTION_TYPES, useCase, topics = [] }) => {
  const topicsToFilter = useCase ? [useCase] : topics
  const topicFilter = (nodes) => nodes.filter((n) => (n.topics || []).some((u) => topicsToFilter.includes(u)))
  const solutionTypes = type && SOLUTION_TYPES.includes(type) ? [type] : types.filter((t) => SOLUTION_TYPES.includes(t))
  // Use the node providers to get the nodes for each type, then filter them by use case
  // and return a flattened array of nodes.
  const nodes = solutionTypes
    .map((solutionType) =>
      topicFilter(SOLUTION_NODES[solutionType]()).map((n) => {
        n.type = solutionType
        return n
      })
    )
    .flat()

  return <>{render(nodes || null)}</>
}
