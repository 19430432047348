import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import semver from "semver"

import Aside from "~components/aside/aside"
import Link from "~components/link/link"

const gql = graphql`
  query {
    sdkVersionsJson {
      js
      rust
      go
    }
  }
`

export const SDKS = {
  rust: {
    link: "https://docs.rs/fastly",
    label: "Rust SDK",
    changelogToken: "rust-sdk",
  },
  js: {
    link: "https://js-compute-reference-docs.edgecompute.app/",
    label: "JavaScript SDK",
    changelogToken: "javascript-sdk",
  },
  go: {
    link: "https://pkg.go.dev/github.com/fastly/compute-sdk-go",
    label: "Go SDK",
    changelogToken: "go-sdk",
  },
}

const SDKWarnings = ({ sdks }) => {
  const latestVersions = useStaticQuery(gql).sdkVersionsJson

  if (!sdks) return null

  if (Object.keys(sdks).length > 1) {
    let includesOutdatedVersion = false
    const listContent = Object.entries(sdks)
      .filter(([lang]) => lang in SDKS && lang in latestVersions)
      .map(([lang, version]) => {
        if (semver.gte(version, latestVersions[lang])) {
          return (
            <React.Fragment key={lang}>
              <Link to={SDKS[lang].link}>{SDKS[lang].label}</Link>: {version} (current)
            </React.Fragment>
          )
        } else {
          includesOutdatedVersion = true
          return (
            <React.Fragment key={lang}>
              <Link to={SDKS[lang].link}>{SDKS[lang].label}</Link>: {version} (current is {latestVersions[lang]}, see{" "}
              <Link to={`/reference/changes/${SDKS[lang].changelogToken}`}>changes</Link>)
            </React.Fragment>
          )
        }
      })
      .reduce((prev, curr) => [prev, ", ", curr])

    return (
      <Aside type={includesOutdatedVersion ? "important" : "note"}>
        {includesOutdatedVersion && <strong>IMPORTANT: </strong>}The content on this page uses the following versions of
        Compute SDKs: {listContent}
      </Aside>
    )
  } else {
    const lang = Object.keys(sdks)[0]
    const version = sdks[lang]
    if (semver.gte(version, latestVersions[lang])) {
      return (
        <Aside type="note">
          The guidance on this page will work with the latest version ({version}) of the{" "}
          <Link to={SDKS[lang].link}>{SDKS[lang].label}</Link>
        </Aside>
      )
    } else {
      return (
        <Aside type="important">
          The guidance on this page was tested with an older version ({version}) of the{" "}
          <Link to={SDKS[lang].link}>{SDKS[lang].label}</Link>. It may still work with the latest version (
          {latestVersions[lang]}), but the{" "}
          <Link to={`/reference/changes/${SDKS[lang].changelogToken}`}>change log</Link> may help if you encounter any
          issues.
        </Aside>
      )
    }
  }
}

export default SDKWarnings
