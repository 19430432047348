export const READY_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSED: 2,
}

class Stream {
  constructor(sourceUrl, options) {
    this.state = 2
    this.lastID = 0
    this.stream = null

    // Use local domain proxy for the DevRel log-bin app running in app engine,
    // because it is available as a backend on developer.fastly.com
    this.sourceUrl = sourceUrl.replace(
      /https:\/\/log-bin(\.fastly\.dev|-dot-rd---product\.uc\.r\.appspot\.com)/,
      "https://developer.fastly.com/logs/demo"
    )
    this.options = {
      timeKeys: ["time", "datetime", "timestamp", "logtime", "eventtime", "datestamp"],
      msgKeys: ["msg", "message", ""],
      metaKeys: [],
      ...options,
    }
    this.handlers = {}
    this.visibilityChangeHandler = this.handleVisibilityChange.bind(this)
    document.addEventListener("visibilitychange", this.visibilityChangeHandler)
  }

  connect() {
    return new Promise((resolve) => {
      if (this.stream) this.stream.close()
      this.stream = new EventSource(this.sourceUrl)
      this.stream.addEventListener("open", () => {
        this.setConnectionState()
        resolve()
      })
      this.stream.addEventListener("error", () => this.setConnectionState())
      for (const name in this.handlers) {
        this.stream.addEventListener(name, this.emit.bind(this, name))
      }
    })
  }

  setConnectionState() {
    if (this.state !== this.stream.readyState) {
      this.state = this.stream.readyState
      this.emit("stateChange", { data: this.state })
    }
  }

  on(eventName, fn) {
    if (!(eventName in this.handlers)) {
      this.handlers[eventName] = new Set()
    }
    this.handlers[eventName].add(fn)
    if (this.stream && this.stream.readyState === READY_STATES.OPEN) {
      this.stream.addEventListener(eventName, this.emit.bind(this, eventName))
    }
  }

  off(eventName) {
    delete this.handlers[eventName]
  }

  emit(eventName, evt) {
    if (evt && evt.lastEventId) {
      if (Number.parseInt(evt.lastEventId, 10) <= Number.parseInt(this.lastID, 10)) return
      this.lastID = evt.lastEventId
    }
    if (!this.handlers[eventName]) return
    let data = (evt && evt.data) || {}
    if (eventName === "log") {
      const rawData = JSON.parse(data)
      const fieldData = rawData.fields
      data = { id: evt.lastEventId, raw: rawData.raw, time: rawData.time }

      // Parse primary log message field
      const msgKey = this.options.msgKeys.find((k) => k in fieldData)
      if (msgKey) {
        data.message = fieldData[msgKey].value
        delete fieldData[msgKey]
      } else if (!Object.keys(fieldData).length) {
        data.message = data.raw
      } else {
        data.message = null
      }

      // If metaKeys is provided, filter out any unwanted fields
      data.fields = Object.keys(fieldData).reduce((out, key) => {
        if (!this.options.metaKeys || this.options.metaKeys.length === 0 || this.options.metaKeys.includes(key)) {
          out[key] = fieldData[key]
        }
        return out
      }, {})
    }
    this.handlers[eventName].forEach((fn) => fn.call(null, data))
  }

  handleVisibilityChange() {
    const isVisible = document.visibilityState === "visible"
    const isConnected = this.stream.readyState === 0 || this.stream.readyState === 1
    if (isVisible && !isConnected) {
      this.connect()
      this.emit("reconnect")
    } else if (!isVisible && isConnected) {
      this.stream.close()
      this.setConnectionState()
    }
  }

  close() {
    document.removeEventListener("visibilitychange", this.visibilityChangeHandler)
    this.stream.close()
  }
}

export default Stream
