import React from "react"
import Link from "~components/link/link"
import * as styles from "./breadcrumbs.module.css"

export default ({ path }) => (
  <ol className={styles.breadcrumbs}>
    {(path || []).map(({ path, title, label }) => (
      <li key={path}>
        <Link to={path}>{label || title}</Link>
      </li>
    ))}
  </ol>
)
