import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const Provider = () => {
  const gql = graphql`
    query {
      demos: allSitePage(
        filter: { pageContext: { template: { eq: "demo" } } }
        sort: { fields: [pageContext___title], order: ASC }
      ) {
        nodes {
          path
          context: pageContext {
            id
            title
            description
            topics
          }
        }
      }
      images: allFile(filter: { relativeDirectory: { eq: "solutions/demos" }, extension: { eq: "png" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `
  const query = useStaticQuery(gql)
  const imageFor = (demo) => query.images.nodes.find(({ name }) => demo && demo.id && name === demo.id)?.publicURL
  return query.demos.nodes.map(({ path, context }) => ({ path, publicImage: imageFor(context), ...context }))
}

export default ({ render }) => {
  const nodes = Provider()

  return <>{render(nodes || null)}</>
}
