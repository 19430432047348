import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { table, labelCol, state, happy, angry, bored, disappointed, wasAngry } from "./freshness-table.module.css"

const Happy = () => <td className={clsx(state, happy)}>😀</td>

const Bored = () => <td className={clsx(state, bored)}>😴</td>

const Angry = () => <td className={clsx(state, angry)}>😡</td>

const BoredWasAngry = () => <td className={clsx(state, bored, wasAngry)}>😴</td>

const DisappointedWasAngry = () => <td className={clsx(state, disappointed, wasAngry)}>😐</td>

const FreshnessTable = ({ isImproved, platform }) => (
  <table className={table}>
    <tbody>
      {platform === "vcl" ? (
        <>
          <tr>
            <th rowSpan={2} colSpan={2}></th>
            <th colSpan={4}>Content state</th>
          </tr>
          <tr>
            <td>Fresh</td>
            <td>SWR</td>
            <td>SIE</td>
            <td>None</td>
          </tr>
          <tr>
            <th rowSpan={4} className={labelCol}>
              Origin
              <br />
              state
            </th>
            <td className={labelCol}>Healthy</td>
            <Happy />
            <Happy />
            <Bored />
            <Bored />
          </tr>
          <tr>
            <td className={labelCol}>Erroring</td>
            <Happy />
            <Happy />
            {isImproved ? <BoredWasAngry /> : <Angry />}
            {isImproved ? <DisappointedWasAngry /> : <Angry />}
          </tr>
          <tr>
            <td className={labelCol}>Down</td>
            <Happy />
            <Happy />
            {isImproved ? <BoredWasAngry /> : <Angry />}
            {isImproved ? <DisappointedWasAngry /> : <Angry />}
          </tr>
          <tr>
            <td className={labelCol}>Sick</td>
            <Happy />
            <Happy />
            <Happy />
            {isImproved ? <DisappointedWasAngry /> : <Angry />}
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th rowSpan={2} colSpan={2}></th>
            <th colSpan={3}>Content state</th>
          </tr>
          <tr>
            <td>Fresh</td>
            <td>SWR</td>
            <td>None</td>
          </tr>
          <tr>
            <th rowSpan={3} className={labelCol}>
              Origin
              <br />
              state
            </th>
            <td className={labelCol}>Healthy</td>
            <Happy />
            <Happy />
            <Bored />
          </tr>
          <tr>
            <td className={labelCol}>Erroring</td>
            <Happy />
            <Happy />
            {isImproved ? <DisappointedWasAngry /> : <Angry />}
          </tr>
          <tr>
            <td className={labelCol}>Down</td>
            <Happy />
            <Happy />
            {isImproved ? <DisappointedWasAngry /> : <Angry />}
          </tr>
        </>
      )}
    </tbody>
  </table>
)

FreshnessTable.defaultProps = {
  isImproved: false,
  platform: "vcl",
}

FreshnessTable.propTypes = {
  isImproved: PropTypes.bool,
  platform: PropTypes.string,
}

export default FreshnessTable
