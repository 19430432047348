import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import MdTransformer from "~components/markdown-transformer/markdown-transformer"
import * as styles from "./aside.module.css"

const Aside = ({ type, title, children }) => {
  const [expanded, setExpanded] = useState(false)
  const expandable = Boolean(title)

  const expand = () => {
    if (expandable) {
      setExpanded(!expanded)
    }
  }

  return (
    <div
      className={clsx(styles.aside, {
        [styles.hint]: type === "hint",
        [styles.warning]: type === "warn" || type === "warning",
        [styles.important]: type === "important",
        [styles.note]: type === "note",
        [styles.expandable]: expandable,
      })}
    >
      <div>
        {expandable && !expanded ? (
          <p>
            {title}{" "}
            <span className={styles.expander} onClick={expand}>
              Learn more...
            </span>
          </p>
        ) : (
          <MdTransformer>{children}</MdTransformer>
        )}
      </div>
    </div>
  )
}

Aside.defaultProps = {
  type: "hint",
}

Aside.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
}

export default Aside
