module.exports = [{
      plugin: require('../plugins/fastly-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null,[null,{"default":false,"custom":[{"type":"tabbed-codeblocks","element":"TabbedPanels"},{"type":"panel","element":"div"},{"type":"panel-full","element":"div"},{"type":"tabbed-panels","element":"TabbedPanels"},{"type":"side-by-side","element":"Columns"}]}]],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/Developer-Portal/Developer-Portal/node_modules/gatsby-remark-autolink-headers","id":"fc65d931-a715-58f7-9e33-ed6d3804ea24","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/home/runner/work/Developer-Portal/Developer-Portal/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"className":"anchor","icon":"<span></span>"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/Developer-Portal/Developer-Portal","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"anchor","icon":"<span></span>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../plugins/fastly-easter-eggs/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-48JBLY0QWK"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"allow_google_signals":false},"pluginConfig":{"exclude":[],"origin":"https://developer.fastly.com"}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"allowUrls":["https://www.fastly.com/documentation"],"dsn":"https://53f7cf8b1bb64c47a10a4f8ce027f0a3@o1025883.ingest.sentry.io/6066959","tunnel":"/documentation/api/internal/errors","sampleRate":0.7,"ignoreErrors":["ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."],"tracesSampleRate":0.7,"release":"6cbcbf09011fb1df7480c8dd8ff2f9cd5f0136d8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
