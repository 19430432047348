import removeHtmlTags from "~utils/remove-html-tags"

const truncateContent = (str, maxLength) => {
  str = String(str ?? "")
  ;[str] = str.split(/<\/(p|P)(>|\s)/, 1)
  str = removeHtmlTags(str)
  if (maxLength && maxLength < str.length) {
    str = str.substr(0, maxLength).replace(/([.!?]) (.*)$/, "$1")
  }
  return str
}

export default truncateContent
