import React from "react"
import { H5 } from "@fastly/consistently"
import Link from "~components/link/link"
import * as styles from "./definition-list.module.css"

const DefinitionList = ({ terms }) => {
  const indexLetters = Array.from(new Set(terms.map((t) => t.term.substr(0, 1)))).sort()

  return (
    <div className={styles.termsList}>
      <ol className={styles.index}>
        {indexLetters.map((letter) => (
          <li>
            <a href={`#${letter}`}>{letter}</a>
          </li>
        ))}
      </ol>
      {terms.map((t, idx, all) => {
        const firstLetter = t.term.substr(0, 1)
        const isNewLetter = !idx || all[idx - 1].term.substr(0, 1) !== firstLetter
        return (
          <div key={t.slug}>
            <H5 as="h3">
              {isNewLetter && <span id={firstLetter} />}
              <Link className="anchor" to={`#${t.slug}`} id={t.slug} />
              {t.term}
            </H5>
            <ul>
              {t.definitions.map((d, idx) => (
                <li key={idx} className={d.isCurrent === false ? styles.outdated : ""}>
                  {Boolean(d.context) && <span className={styles.context}>{d.context}</span>}
                  {d.isCurrent === false && <span className={styles.outdated}>Outdated usage</span>}
                  <span dangerouslySetInnerHTML={{ __html: d.html }} />
                  {d.link && (
                    <Link className={styles.learnMore} to={d.link}>
                      Learn more
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default DefinitionList
