import React from "react"
import { Box } from "@fastly/consistently-vanilla"

const Grid = ({ children, ...props }) => {
  return (
    <Box display="grid" {...props}>
      {children}
    </Box>
  )
}

export { Grid }
