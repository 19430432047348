import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { marked } from "marked"
import slugify from "slugify"

const unHTML = (htmlStr) =>
  htmlStr
    .replace(/(<([^>]+)>)/gi, "")
    .replace("&amp;", "&")
    .replace("&quot;", '"')
    .replace(/&#(\d+);/g, (m, m1) => String.fromCharCode(m1))

export default ({ slug, render }) => {
  const gql = graphql`
    query {
      terms: allTermsYaml(sort: { fields: term, order: ASC }) {
        nodes {
          term
          definition
          isCurrent
          link
          definitions {
            context
            definition
          }
        }
      }
    }
  `

  const nodes = useStaticQuery(gql).terms.nodes.map(({ term, link, definition, isCurrent, definitions }) => ({
    term,
    slug: "term-" + slugify(term.replace(/\(.*\)/, "")).toLowerCase(),
    definitions: (definitions || [])
      .concat(definition ? { definition, isCurrent, link } : [])
      .map(({ definition, context, isCurrent, link }) => {
        const html = marked
          .parse(definition)
          .trim()
          .replace(/^<p>(.*)<\/p>$/, "$1") // Unwrap an outer paragraph
        return {
          html,
          plain: unHTML(html),
          isCurrent,
          context,
          link,
        }
      }),
  }))

  const val = slug ? nodes.find((n) => n.slug === slug) : nodes

  return <>{render(val || null)}</>
}
