import React from "react"
import clsx from "clsx"
import { Link as GatsbyLink } from "gatsby"
import { basename } from "path"
import { NON_GATSBY_LINK, ANCHOR_LINK, WWW_LINK, DOWNLOAD_LINK } from "~utils/path"
import isClientSide from "~utils/is-client-side"
import { stripPrefix } from "~utils/path"

const Link = ({ to, ...props }) => {
  if (!to) return null

  const href = stripPrefix(to)

  function warnOnNavigate(ev) {
    if (
      isClientSide() &&
      document.querySelector(".warn-on-navigate") &&
      !ev.metaKey &&
      !ev.shiftKey &&
      !window.confirm(`You may lose your work if you navigate away from this page. Are you sure?`)
    ) {
      ev.preventDefault()
      return false
    }
  }

  const isFastlyLink = WWW_LINK.test(to)

  // Anchor links to stay on the same page
  const externalProps = !ANCHOR_LINK.test(to) && !isFastlyLink && { target: "_blank", rel: "noopener noreferrer" }
  if (href === `#${props.id}`) props.title = "Link directly to this section of the page"

  // Links with an 'anchor' class and no content get an ARIA label
  if ((props.className || "").split(" ").includes("anchor") && !props.children) {
    props["aria-label"] = "Permalink"
  }

  // Links to the downloads folder should have a download attribute
  if (DOWNLOAD_LINK.test(href)) props.download = basename(to)

  const classNames = clsx(props.className, {
    blueLink: !props.noBlueLink,
  })

  // Don't double-prefix on non-Gatsby links
  return NON_GATSBY_LINK.test(to) ? (
    <a onClick={warnOnNavigate} href={href} {...props} className={classNames} {...externalProps} /> // eslint-disable-line jsx-a11y/anchor-has-content
  ) : (
    <GatsbyLink onClick={warnOnNavigate} to={href} {...props} className={classNames} />
  )
}

export default Link
