import React from "react"
import { useStaticQuery, graphql } from "gatsby"

let tokCache

export default ({ query, render }) => {
  let token

  if (typeof query === "string") {
    if (!tokCache) {
      const gql = graphql`
        query {
          vclTokens: allSitePage(
            filter: { pageContext: { tokenType: { regex: "/^(function|variable|statement|subroutine|type)$/" } } }
          ) {
            nodes {
              path
              context: pageContext {
                title
                tokenType
              }
            }
          }
        }
      `
      tokCache = useStaticQuery(gql).vclTokens.nodes.reduce(
        (out, t) => ({ ...out, [t.context.title]: { name: t.context.title, path: t.path, type: t.context.tokenType } }),
        {}
      )
    }

    token = tokCache[query.replace(/\(\)$/, "")]
  }

  return <>{render(token || null)}</>
}
