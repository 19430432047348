/**
 * Provide navigation tree
 *
 * return [ {
 *     title: string;
 *     path: string;
 *     isActive: bool;
 * } ]
 */

import { useStaticQuery, graphql } from "gatsby"
import { normalizePath } from "~utils/path"

// Convert a flat list of nodes into a tree structure
const makeTree = (nodeList) => {
  let currentBranch, childNodes
  return nodeList
    .reduce((out, n) => {
      if (currentBranch && n.path.startsWith(currentBranch.path)) {
        childNodes.push(n)
      } else {
        if (currentBranch) {
          out.push({ ...currentBranch, children: makeTree(childNodes) })
        }
        currentBranch = n
        childNodes = []
      }
      return out
    }, [])
    .concat(currentBranch ? { ...currentBranch, children: makeTree(childNodes) } : [])
    .sort((a, b) =>
      (a.order ?? Number.MAX_SAFE_INTEGER) > (b.order ?? Number.MAX_SAFE_INTEGER) ||
      ((a.order ?? Number.MAX_SAFE_INTEGER) === (b.order ?? Number.MAX_SAFE_INTEGER) && a.title > b.title)
        ? 1
        : -1
    )
}

// Mark nodes in a tree structure that match a path
const isOnActivePath = (leafPath, testPath) => leafPath.startsWith(testPath)
const markActivePath = (leafPath, node) => ({
  ...node,
  isActive: isOnActivePath(leafPath, node.path),
  children: (node.children || []).map(markActivePath.bind(null, leafPath)),
})

export default (activePath) => {
  const gql = graphql`
    query {
      sections: allSitePage(filter: { path: { ne: "/" } }, sort: { fields: [path], order: ASC }) {
        nodes {
          path
          context: pageContext {
            title
            navTitle
            label
            sidebarRoot
            order
            externalUrl
            unlisted
            hideChildren
            vcl_write
            components
            summary
            platform
          }
        }
      }
      cliCommands: allCliCommandsJson(sort: { fields: slug, order: ASC }) {
        nodes {
          path: gatsbyPath(filePath: "/reference/cli/{cliCommandsJson.slug}")
          name
        }
      }
    }
  `

  const { sections, cliCommands } = useStaticQuery(gql)

  // Map CLI commands by path.
  const cliCommandsByPath = cliCommands.nodes.reduce((out, c) => {
    out[c.path] = { title: c.name }
    return out
  }, {})
  const nodeList = sections.nodes.map(({ path, context = {} }) => {
    if (path.startsWith("/reference/cli/") && cliCommandsByPath[path]) {
      context = cliCommandsByPath[path]
    }
    const {
      title,
      navTitle,
      label,
      sidebarRoot,
      order = 0,
      unlisted,
      hideChildren,
      vcl_write,
      components,
      externalUrl,
      summary,
      platform,
    } = context || {}
    return {
      path: normalizePath(path),
      title: navTitle || title,
      label,
      sidebarRoot,
      externalUrl,
      order,
      unlisted: navTitle || title ? Boolean(unlisted) : true,
      hideChildren: Boolean(hideChildren),
      vcl_write: Boolean(vcl_write),
      components: components || [],
      summary,
      platform,
    }
  })

  const navigationTree = { title: "Home", path: "/", children: makeTree(nodeList) }
  const navTree = markActivePath(normalizePath(activePath), navigationTree)
  const breadcrumbs = extractActivePath(navTree)
    .filter((node) => node.title || node.label)
    .slice(0, -1)

  return {
    navTree,
    extractTree,
    extractActivePath,
    childrenOfPath,
    breadcrumbs,
  }
}

// Extract a section of a tree that matches a filter
export const extractTree = (tree, isRoot) =>
  tree &&
  (isRoot(tree)
    ? tree
    : extractTree(
        (tree.children || []).find((n) => n.isActive),
        isRoot
      ))

export const extractActivePath = (node) =>
  node &&
  [
    { title: node.title, navTitle: node.navTitle, path: node.path, label: node.label, externalUrl: node.externalUrl },
  ].concat(extractActivePath(node.children.find((n) => n.isActive)) || [])

export const childrenOfPath = (node, path) =>
  node &&
  (node.path === normalizePath(path)
    ? node.children
    : childrenOfPath(
        node.children.find((n) => n.isActive),
        path
      ))
