import React, { useEffect, Suspense, useState } from "react"

const Component = React.lazy(() => import("./component"))

const GlobeTrafficAnimation = ({ height }) => {
  const [isClient, setClient] = useState(false)

  useEffect(() => setClient(true), [])
  if (!isClient) return null

  return (
    <Suspense fallback="">
      <Component />
    </Suspense>
  )
}

export default GlobeTrafficAnimation
