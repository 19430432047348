import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as langLabels from "~utils/language-labels"

const difficultyLabel = {
  0: "Beginner",
  1: "Medium",
  2: "Difficult",
}

export default ({ sort, render }) => {
  const [userData, setUserData] = useState({})
  const gql = graphql`
    query {
      challenges: allSitePage(filter: { pageContext: { template: { eq: "challenge" } } }) {
        nodes {
          path
          context: pageContext {
            title
            summary
            implementations {
              rust {
                fiddleID
              }
              vcl {
                fiddleID
              }
              javascript {
                fiddleID
              }
              go {
                fiddleID
              }
            }
            level
          }
        }
      }
    }
  `
  const nodes = useStaticQuery(gql).challenges.nodes

  useEffect(() => {
    fetch("https://developer.fastly.com/api/internal/challenges")
      .then((resp) => resp.json())
      .then((data) => {
        setUserData(data)
      })
  }, [])

  const results = nodes.map(({ path, context }) => ({
    path,
    difficultyLabel: difficultyLabel[context.level],
    languages: [
      ...Object.entries(context.implementations).reduce(
        (acc, [k, v]) => (v && v.fiddleID ? [...acc, langLabels[k]] : acc),
        []
      ),
    ],
    ...context,
    ...(userData[path.replace(/^.*\/([^/]+)\/?$/, "$1")] || {}),
  }))

  if (sort === "level") {
    results.sort((a, b) => a.level - b.level)
  }

  return <>{render(results || [])}</>
}
