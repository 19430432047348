import React from "react"
import CardGallery from "~components/card/card-gallery"
import useNavigationTree from "~hooks/navigation-tree"

export default ({ path }) => {
  // Find the child pages using the same component that handles sidebar navigation.
  const { navTree, childrenOfPath } = useNavigationTree(path)
  const childPages = childrenOfPath(navTree, path)
  // Render them as cards.
  return (
    <CardGallery
      data={childPages.map((p) => ({
        // For each node `p`, we will select the properties we need to render the card.
        link: p.externalUrl || p.path,
        title: p.title,
        content: <p>{p.summary}</p>,
        labels: [String(p.label || p.platform || "").toUpperCase()].filter((l) => Boolean(l)),
      }))}
    />
  )
}
