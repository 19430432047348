// For docs.fastly.com only, override loadPage & loadPagesync to fix canonical client-side redirects.
exports.onClientEntry = () => {
  const loader = window.___loader

  if (process.env.NODE_ENV === "development" || !loader || window.location.host !== "docs.fastly.com") {
    return
  }

  const pagePath = window.pagePath
  const location = window.location

  if (pagePath && pagePath !== location.pathname && pagePath !== location.pathname + "/") {
    const originalLoadPageSync = loader.loadPageSync
    const originalLoadPage = loader.loadPage

    loader.loadPageSync = (path) => {
      let pageResources
      // If the path is the same as our current page, it's not a prefetch.
      if (path === location.pathname) {
        pageResources = originalLoadPageSync(pagePath)
      } else {
        pageResources = originalLoadPageSync(path)
      }

      if (pageResources.page) {
        pageResources.page.matchPath = "*"
      }

      return pageResources
    }

    loader.loadPage = (path) => {
      let pageResources
      // If the path is the same as our current page, it's not a prefetch.
      if (path === location.pathname) {
        pageResources = originalLoadPage(pagePath)
      } else {
        pageResources = originalLoadPage(path)
      }

      if (pageResources.page) {
        pageResources.page.matchPath = "*"
      }

      return pageResources
    }
  }

  // Disable prefetch on hover.
  loader.hovering = () => {}
}

// Disable prefetching: we don't know the exact page-data path.
exports.disableCorePrefetching = () => true
