import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz"

const toDateStr = (start, end, timeZone) => {
  const format = (date, fmt) => formatInTimeZone(date, timeZone, fmt)
  if (!end) return format(start, "d LLL yyyy")
  if (end.getYear() !== start.getYear()) return format(start, "d LLL yyyy") + " - " + format(end, "d LLL yyyy")
  if (end.getMonth() !== start.getMonth()) return format(start, "d LLL ") + " - " + format(end, "d LLL, yyyy")
  return format(start, "d") + "-" + format(end, "d LLL yyyy")
}

export default ({ filter, limit = 100, render }) => {
  const gql = graphql`
    query {
      events: allGoogleExportgatsbySheet(
        filter: { approvedBy: { ne: null }, title: { ne: "---" } }
        sort: { fields: startDate, order: ASC }
      ) {
        nodes {
          title
          startDate
          endDate
          startTime
          tz
          createdBy
          href
          isStreamed
          location
          cost
          currency
          category
          teaser
          createdBy
          approvedBy
        }
      }
    }
  `

  const now = new Date()
  const nodes = useStaticQuery(gql)
    .events.nodes.map((n) => {
      const timeZone = n.tz ? n.tz.replace(/^(\w+\/\w+).*?$/, "$1") : "UTC"
      const startDate = zonedTimeToUtc(n.startDate, timeZone)
      const endDate = n.endDate ? zonedTimeToUtc(n.endDate, timeZone) : null

      return {
        ...n,
        startDate,
        endDate,
        category: n.category.replace(/^(\w+)( .*)$/, "$1").toLowerCase(),
        dateTimeStr: toDateStr(startDate, endDate, timeZone),
        isStreamed: n.isStreamed === "Yes",
        cost: Number.parseFloat(n.cost),
        priceStr: Number.parseFloat(n.cost) > 0 ? n.currency + " " + n.cost : "Free",
      }
    })
    .filter((n) => (filter === "future" ? n.startDate > now : true))
    .slice(0, limit)

  return <>{render(nodes || [])}</>
}
