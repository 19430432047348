import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import clsx from "clsx"

import Breadcrumbs from "~components/breadcrumbs/breadcrumbs"
import LabelList from "~components/label-list/label-list"
import Link from "~components/link/link"
import useNavigationTree from "~hooks/navigation-tree"

import * as styles from "./api-index.module.css"

function useApiEndpointsForIndex() {
  const gql = graphql`
    query {
      collections: allApiCollection(sort: { order: ASC, fields: data___path }) {
        nodes {
          data {
            endpoints {
              title
              path
              method
              deprecated
              description
              authentication
              slug
              url
            }
            section
            title
            description
            path
            unlisted
          }
        }
      }
    }
  `

  const results = useStaticQuery(gql)

  const endpointsBySection = results.collections.nodes
    .filter(({ data: { unlisted = false } }) => !unlisted)
    .filter(({ data: { endpoints } }) => Array.isArray(endpoints) && endpoints.length > 0)
    .map(({ data }) => ({
      id: data.section,
      title: data.title,
      description: data.description,
      endpoints: data.endpoints,
      path: data.path,
      deprecated: data.endpoints.every((endpoint) => endpoint.deprecated),
    }))

  return {
    endpointsBySection,
  }
}

function DeprecatedBadge() {
  return (
    <span className={styles.deprecated}>
      <LabelList background labels={["Deprecated"]} />
    </span>
  )
}

export default () => {
  const { endpointsBySection } = useApiEndpointsForIndex()

  return (
    <>
      {Object.entries(endpointsBySection).map(([id, section]) => {
        const { breadcrumbs } = useNavigationTree(`/reference/api/${section.path}`)

        // Include paths in the trail that are under the Reference API
        const breadcrumbTrail = breadcrumbs.filter(({ path }) => path.startsWith(`/reference/api/`))

        if (breadcrumbTrail.length === 0) {
          // If the reference page doesn't exist in navTree, then don't add this page
          // as links would be broken
          return null
        }

        return (
          <div key={id}>
            <h2>
              <Link className="anchor" to={`#${section.id}`} id={section.id} />
              {section.title} {section.deprecated ? <DeprecatedBadge /> : null}
            </h2>
            <p className={styles.referenceLink}>
              <Breadcrumbs path={breadcrumbTrail} />
              <Link to={`/reference/api/${section.path}`}>{section.title}</Link>
            </p>
            <div dangerouslySetInnerHTML={{ __html: section.description }} />
            <div className="tableWrapper">
              <table>
                <thead>
                  <tr>
                    <th className={styles.endpointTitleColumn}>Action</th>
                    <th>Endpoint</th>
                  </tr>
                </thead>
                <tbody>
                  {section.endpoints.map(({ title, method, url, slug, deprecated }, i) => (
                    <tr key={i}>
                      <td className={styles.endpointTitleColumn}>
                        <span className={styles.endpointTitle}>{title}</span>
                        {deprecated ? <DeprecatedBadge /> : null}
                      </td>
                      <td>
                        <div className={clsx(styles.endpointUrl, styles[`${method.toLowerCase()}Endpoint`])}>
                          <span className={styles.methodLabel}>{method}</span>
                          <Link className={styles.endpointPath} to={`/reference/api/${section.path}#${slug}`}>
                            {url.split(/([/?])/).map((param, i) => (
                              <React.Fragment key={i}>
                                {param.startsWith("{") ? <code>{param.slice(1, -1)}</code> : param}
                              </React.Fragment>
                            ))}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
    </>
  )
}
