import React from "react"
import Link from "~components/link/link"
import Icon from "~components/icon/icon"

const renderItem = ({ title, path, vcl_write }) => {
  if (vcl_write) return <Link to={path}>{title}</Link>

  return (
    <>
      <Link to={path}>{title}</Link>
      &nbsp;
      <Icon id="shield" tip="This header is protected and cannot be modified in VCL" />
    </>
  )
}

export default renderItem
