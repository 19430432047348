import React from "react"
import Markdown from "markdown-to-jsx"

const MdTransformer = ({ children }) => {
  if (typeof children === "string" && children !== "") {
    return <Markdown>{children}</Markdown>
  }
  if (typeof children === "object" && children.length) {
    return Object.values(children).reduce((acc, value) => {
      // value != " " avoids transforming {" "} into an empty <span>
      if (typeof value === "string" && value !== " ") {
        acc.push(<Markdown>{value}</Markdown>)
      } else {
        acc.push(value)
      }
      return acc
    }, [])
  }
  return children
}

export default MdTransformer
