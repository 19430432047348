import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const Provider = () => {
  const gql = graphql`
    query {
      topics: allSitePage(
        filter: { path: { regex: "/^/solutions/use-cases/.+/" } }
        sort: { fields: [pageContext___title], order: ASC }
      ) {
        nodes {
          path
          context: pageContext {
            title
            description
            featured
            image {
              href
            }
          }
        }
      }
      images: allFile(filter: { relativeDirectory: { glob: "solutions/topics/*" }, extension: { eq: "png" } }) {
        nodes {
          publicURL
          relativePath
        }
      }
    }
  `
  const query = useStaticQuery(gql)
  const imageFor = (path, picture) =>
    query.images.nodes.find(({ relativePath }) => `${path.slice(1, -1)}${picture}` === relativePath)?.publicURL
  return query.topics.nodes.map(({ path, context }) => ({
    path,
    publicImage: imageFor(path, context.image.href),
    ...context,
  }))
}

export default ({ render }) => {
  const nodes = Provider()

  return <>{render(nodes || null)}</>
}
