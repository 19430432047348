import React from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Aside from "~components/aside/aside"
import Link from "~components/link/link"
import { SDKS } from "~components/sdk-warnings/sdk-warnings"

import * as styles from "./language-matrix.module.css"

const gql = graphql`
  query {
    sdkVersionsJson {
      js
      rust
      go
    }
  }
`

export default () => {
  const latestVersions = useStaticQuery(gql).sdkVersionsJson
  return (
    <>
      <table>
        <tbody>
          <tr>
            <th></th>
            <td>
              <div className={styles.language}>
                <img src={withPrefix(`/icons/languages/rust.svg`)} data-tip="Rust" alt="Rust logo" />
              </div>
            </td>
            <td>
              <div className={styles.language}>
                <img src={withPrefix(`/icons/languages/javascript.svg`)} data-tip="JavaScript" alt="JavaScript logo" />
              </div>
            </td>
            <td>
              <div className={styles.language}>
                <img src={withPrefix(`/icons/languages/go.svg`)} data-tip="Go" alt="Go logo" />
              </div>
            </td>
          </tr>
          <tr className={styles.subFeature}>
            <th>Current SDK version</th>
            <td>
              <Link to={SDKS.rust.link}>{latestVersions.rust}</Link>
            </td>
            <td>
              <Link to={SDKS.js.link}>{latestVersions.js}</Link>
            </td>
            <td>
              <Link to={SDKS.go.link}>{latestVersions.go}</Link>
            </td>
          </tr>
          <tr>
            <th>Equally supported</th>
            <td colSpan={3}>
              <Link to="/guides/concepts/geolocation/">Geolocation</Link>,{" "}
              <Link to="/guides/concepts/compression">Auto decompression</Link>,{" "}
              <Link to="/guides/concepts/compression">Dynamic compression</Link>,{" "}
              <Link to="/reference/compute/ecp-env/">Environment variables</Link>,{" "}
              <Link to="/guides/concepts/edge-state/cache/cache-freshness/#pre-defining-cache-behavior-on-requests">
                Cache override
              </Link>
              , <Link to="/guides/integrations/logging">Real time logging</Link>,{" "}
              <Link to="/guides/concepts/edge-state/data-stores/">KV stores</Link>,{" "}
              <Link to="/guides/concepts/edge-state/dynamic-config/#config-stores">Config stores</Link>,{" "}
              <Link to="/guides/concepts/edge-state/dynamic-config/#secret-stores">Secret stores</Link>,{" "}
              <Link to="/guides/concepts/real-time-messaging/fanout/">Fanout</Link>,{" "}
              <Link to="/guides/integrations/backends/#dynamic-backends">Dynamic backends</Link>,{" "}
              <Link to="/guides/concepts/edge-state/cache/#simple-cache">Simple cache</Link>,{" "}
              <Link to="/guides/concepts/edge-state/cache/#readthrough-cache">Readthough cache</Link>
            </td>
          </tr>
          <tr>
            <th colSpan={4}>Core feature differences</th>
          </tr>
          <tr className={styles.subFeature}>
            <th>
              WebSockets passthrough <Link to="/guides/concepts/real-time-messaging/websockets-tunnel">ℹ️</Link>
            </th>
            <td>✅</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>Mutual TLS for origin fetch</th>
            <td>✅</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>
              Core cache interface <Link to="/guides/concepts/edge-state/cache/#core-cache">ℹ️</Link>
            </th>
            <td>✅</td>
            <td>✅</td>
            <td>✅</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>
              Readthrough cache - custom cache keys{" "}
              <Link to="/guides/concepts/edge-state/cache/#controlling-the-cache-key">ℹ️</Link>
            </th>
            <td>✅</td>
            <td>✅</td>
            <td>-</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>
              Readthrough cache - customize cache behavior{" "}
              <Link to="/guides/concepts/edge-state/cache/#customizing-cache-interaction-with-the-backend">ℹ️</Link>
            </th>
            <td>✅</td>
            <td>-</td>
            <td>-</td>
          </tr>

          <tr>
            <th colSpan={5}>Pre-release feature differences</th>
          </tr>
          <tr className={styles.subFeature}>
            <th>
              Purging <span className={styles.note}>see note below</span>
            </th>
            <td>✅</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>Backend health</th>
            <td>✅</td>
            <td>✅</td>
            <td>-</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>Device Detection</th>
            <td>✅</td>
            <td>✅</td>
            <td>✅</td>
          </tr>
          <tr className={styles.subFeature}>
            <th>
              Edge rate limiting <Link to="/guides/concepts/rate-limiting">ℹ️</Link>
            </th>
            <td>✅</td>
            <td>✅</td>
            <td>✅</td>
          </tr>

          <tr>
            <th></th>
            <td>
              <Link to="/guides/compute/rust">More about Rust &raquo;</Link>
            </td>
            <td>
              <Link to="/guides/compute/javascript">More about JavaScript &raquo;</Link>
            </td>
            <td>
              <Link to="/guides/compute/go">More about Go &raquo;</Link>
            </td>
          </tr>
        </tbody>
      </table>

      <Aside type="note">
        <ol>
          <li>
            Purging from edge code allows purge operations to be triggered when processing edge requests. Purging via
            our API is available regardless of which SDK is used.
          </li>
        </ol>
      </Aside>
    </>
  )
}
