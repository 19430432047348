import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default ({ sort, render }) => {
  const gql = graphql`
    query {
      pops: allPopsJson(sort: { fields: name }) {
        nodes {
          popCode
          sites
          name
          pnis
          shieldCode
          recommend
          geo {
            lat
            lng
          }
        }
      }
    }
  `
  const nodes = useStaticQuery(gql).pops.nodes

  return <>{render(nodes || [])}</>
}
