import React, { useState, useRef } from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

import Aside from "~components/aside/aside"
import Icon from "~components/icon/icon"

import * as styles from "./shield-recommender.module.css"

const gql = graphql`
  query {
    pops: allPopsJson(sort: { fields: name }) {
      nodes {
        popCode
        name
        pnis
        shieldCode
        recommend
        hostCount
      }
    }
  }
`
const PROVIDERS = {
  aws: "Amazon Web Services",
  gcp: "Google Cloud Platform",
  azure: "Microsoft Azure",
}
const CAPACITY_ICONS = [
  { min: 150, sizeName: "capLarge" },
  { min: 50, sizeName: "capMedium" },
  { min: 0, sizeName: "capSmall" },
]

export default () => {
  const [readyState, setReadyState] = useState("idle")
  const [searchHost, setSearchHost] = useState("")
  const [recommendationData, setRecommendationData] = useState([])
  const inputEl = useRef()

  const pops = useStaticQuery(gql).pops.nodes.filter((n) => !!n.shieldCode)

  const search = async () => {
    setReadyState("searching")
    inputEl.current.blur()
    const searchTxt = searchHost
    try {
      const resp = await fetch("https://developer.fastly.com/api/internal/shieldRecommend?dest=" + searchTxt)
      const data = await resp.json()
      setRecommendationData(data)
      const numResults = data.popResults.length
      if (new Set(data.ips.map((ip) => ip.country_name)).size > 1 || (data.avgPing > 0 && data.avgPing < 50)) {
        setReadyState("multi-ip")
      } else if (numResults > 10) {
        setReadyState("result")
      } else {
        setReadyState("error")
      }
    } catch (e) {
      setReadyState("error")
    }
  }

  const handleKeypress = (evt) => {
    if (evt.key === "Enter") {
      setTimeout(search, 10)
    } else if (readyState !== "idle") {
      setReadyState("idle")
    }
  }

  return (
    <>
      {
        <Aside type="hint">
          <p>
            <strong>HINT:</strong> Enter your origin hostname below to show the suitability of each POP to be the shield
            location for your origin
          </p>
          <p>
            <input
              type="text"
              value={searchHost}
              onChange={(evt) => setSearchHost(evt.target.value)}
              onKeyUp={handleKeypress}
              ref={inputEl}
              placeholder="my-origin-server.example.com"
            />
          </p>
          <p className={styles.detail}>
            {readyState === "idle" && (
              <>
                We will find the Fastly POP with the fastest link to your origin. If there are multiple fast options,
                we'll prefer those with PNIs.
              </>
            )}
            {readyState === "searching" && (
              <>
                Finding the best shield location for <code>{searchHost}</code>... Please wait (usually about 20
                seconds).{" "}
              </>
            )}
            {readyState === "multi-ip" && (
              <>
                <Icon id="warn" /> Your origin server appears to be hosted in multiple locations. It may be using a CDN.
                Distributed origins usually should not be shielded.
              </>
            )}
            {readyState === "result" && (
              <>
                Displaying results for{" "}
                {recommendationData.pni ? (
                  <span>
                    <Icon
                      key={recommendationData.pni}
                      id={recommendationData.pni}
                      tip={PROVIDERS[recommendationData.pni]}
                      noDarkMode={true}
                    />{" "}
                  </span>
                ) : (
                  ""
                )}
                <code>{searchHost}</code> in the table below. POPs marked 🟢 are good choices for shielding this origin.
              </>
            )}
            {readyState === "error" && (
              <>
                <Icon id="warn" /> There was a problem looking up a recommended shield for this hostname. Check that the
                hostname is valid, the server is online and that it responds to pings.
              </>
            )}
          </p>
        </Aside>
      }
      <div className="tableWrapper">
        <table>
          <thead>
            <tr>
              <th>Location</th>
              <th>POP</th>
              <th>Capacity</th>
              <th>Shield code</th>
              <th data-tip="Private network interconnects">PNIs</th>
              <th>Recommended for</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pops.map((pop) => (
              <tr key={pop.popCode}>
                <td>{pop.name}</td>
                <td>
                  <strong>{pop.popCode}</strong>
                </td>
                <td>
                  <Icon id={CAPACITY_ICONS.find((i) => i.min < pop.hostCount).sizeName} />
                </td>
                <td>
                  <code>{pop.shieldCode}</code>
                </td>
                <td>
                  {(pop.pnis || [])
                    .map((provider) => (
                      <Icon key={provider} id={provider} tip={PROVIDERS[provider]} noDarkMode={true} />
                    ))
                    .reduce((acc, x) => (acc === null ? [x] : [acc, " ", x]), null)}
                </td>
                <td>
                  <small>
                    {(pop.recommend || [])
                      .map((code) => {
                        const [provider, region] = code.split(":", 2)
                        return (
                          <span key={code}>
                            <Icon id={provider} tip={PROVIDERS[provider]} noDarkMode={true} />
                            <code>{region}</code>
                          </span>
                        )
                      })
                      .reduce((acc, x) => (acc === null ? [x] : [acc, <br key="" />, x]), null)}
                  </small>
                </td>
                <td className={styles.rec}>
                  {readyState === "result" &&
                    (() => {
                      const popData = (recommendationData?.popResults || []).find(
                        (entry) => entry.popCode === pop.popCode
                      )
                      if (popData) {
                        if (popData.rtt < 20 && (!recommendationData.pni || pop.pnis.includes(recommendationData.pni)))
                          return (
                            <span title="Great!" data-tip="Great!">
                              🟢
                            </span>
                          )
                        if (popData.rtt < 50)
                          return (
                            <span title="Not ideal" data-tip="Not ideal">
                              🟡
                            </span>
                          )
                        return (
                          <span title="Great!" data-tip="Avoid">
                            🔴
                          </span>
                        )
                      } else {
                        return (
                          <span title="Unknown" data-tip="Unknown">
                            ⚪
                          </span>
                        )
                      }
                    })()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className={styles.detail}>
        Cloud provider recommendations are powered by <a href="https://www.jsdelivr.com">jsDelivr</a>'s{" "}
        <a href="https://www.jsdelivr.com/globalping">Globalping</a> platform. jsDelivr is a member of our{" "}
        <a href="https://www.fastly.com/forward">
          <img
            src={withPrefix(`/fastforward-logo.svg`)}
            alt="Fast Forward logo"
            width={100}
            className={styles.fflogo}
          />
        </a>{" "}
        community program, which nurtures and supports projects that share our vision of an open internet that is fast
        and secure for all.
      </p>
      <hr />
    </>
  )
}
