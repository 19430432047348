// extracted by mini-css-extract-plugin
export var aws = "icon-module--aws--ce6c4 icon-module--icon--42e7d";
export var azure = "icon-module--azure--2206c icon-module--icon--42e7d";
export var capLarge = "icon-module--capLarge--01b6e icon-module--icon--42e7d";
export var capMedium = "icon-module--capMedium--7664b icon-module--icon--42e7d";
export var capSmall = "icon-module--capSmall--fe24e icon-module--icon--42e7d";
export var close = "icon-module--close--66673 icon-module--icon--42e7d";
export var coin = "icon-module--coin--01251 icon-module--icon--42e7d";
export var coinDark = "icon-module--coinDark--fb0df icon-module--icon--42e7d";
export var cross = "icon-module--cross--cd227 icon-module--icon--42e7d";
export var currencyUsd = "icon-module--currency-usd--cb162 icon-module--icon--42e7d";
export var decisionTree = "icon-module--decisionTree--bfb5c icon-module--icon--42e7d";
export var deprecated = "icon-module--deprecated--9bfae icon-module--icon--42e7d";
export var edit = "icon-module--edit--f2547 icon-module--icon--42e7d";
export var external = "icon-module--external--85cd9 icon-module--icon--42e7d";
export var gcp = "icon-module--gcp--0c75e icon-module--icon--42e7d";
export var googleCloud = "icon-module--googleCloud--f1e49 icon-module--icon--42e7d";
export var icon = "icon-module--icon--42e7d";
export var info = "icon-module--info--4a873 icon-module--icon--42e7d";
export var labs = "icon-module--labs--5b181 icon-module--icon--42e7d";
export var notify = "icon-module--notify--46066 icon-module--icon--42e7d";
export var price = "icon-module--price--4a02c icon-module--icon--42e7d";
export var readOnly = "icon-module--readOnly--c444a icon-module--icon--42e7d";
export var recommendation = "icon-module--recommendation--1ab95 icon-module--icon--42e7d";
export var required = "icon-module--required--b7fa2 icon-module--icon--42e7d";
export var shield = "icon-module--shield--470e8 icon-module--icon--42e7d";
export var thumbDown = "icon-module--thumb-down--00724 icon-module--icon--42e7d";
export var tick = "icon-module--tick--b909e icon-module--icon--42e7d";
export var video = "icon-module--video--642cb icon-module--icon--42e7d";
export var warn = "icon-module--warn--d3afb icon-module--icon--42e7d";
export var writeOnly = "icon-module--writeOnly--4cee5 icon-module--icon--42e7d";