import React, { useState } from "react"
import { CodeBlock } from "@fastly/consistently"
import TokenLink from "~components/token-link/token-link"
import Aside from "~components/aside/aside"
import Link from "~components/link/link"
import VclTokensProvider from "~providers/vcl-tokens"
import fields from "./fields"

import * as styles from "./log-formatter.module.css"

const OUTPUT_TYPES = {
  json: {
    name: "JSON",
    formatter: (fields) =>
      "{ " +
      Object.entries(fields)
        .map(([k, v]) => `"${k}": "%{json.escape(${v})}V"`)
        .join(", ") +
      " }",
  },
  qs: {
    name: "querystring",
    formatter: (fields) =>
      Object.entries(fields)
        .map(([k, v]) => `${encodeURIComponent(k)}=%{urlencode(${v})}V`)
        .join("&"),
  },
  csv: {
    name: "CSV",
    formatter: (fields) =>
      Object.values(fields)
        .map((v) => `"%{${v}}V"`)
        .join(","),
  },
}

export default () => {
  const [logFields, setLogFields] = useState({})
  const [output, setOutput] = useState(OUTPUT_TYPES.json)

  function changeOutput(ev) {
    setOutput(OUTPUT_TYPES[ev.target.value])
  }

  function updateFields(ev) {
    setLogFields((prevData) => {
      const newData = { ...prevData }
      if (ev.target.checked) {
        newData[ev.target.name] = fields[ev.target.name].vcl
      } else {
        delete newData[ev.target.name]
      }
      return newData
    })
  }

  function clearFields(ev) {
    ev.preventDefault()
    setLogFields({})
  }

  return (
    <>
      <Aside type="hint">
        <strong>HINT:</strong> Select any variables in the table below to generate a{" "}
        <Link to="/guides/integrations/logging/#generating-logs-automatically-in-vcl">custom log format</Link> in{" "}
        <select className={styles.outputSelector} onChange={changeOutput}>
          {Object.entries(OUTPUT_TYPES).map(([outputType, outputOptions]) => (
            <option key={outputType} value={outputType}>
              {outputOptions.name}
            </option>
          ))}
        </select>{" "}
        format.
        {Object.keys(logFields).length ? (
          <div className={styles.formatter}>
            <CodeBlock className={styles.output} wordWrap={true}>
              {output.formatter(logFields)}
            </CodeBlock>
            <div className={styles.controls}>
              Use the code above as the value of the <code>format</code> property when creating a log endpoint.{" "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a href="#" onClick={clearFields}>
                Clear fields
              </a>
            </div>
          </div>
        ) : null}
      </Aside>
      <div className="tableWrapper">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>VCL</th>
              <th>Compute?</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(fields).map(([fieldName, field]) => (
              <tr key={fieldName}>
                <td>
                  <label className={styles.field}>
                    <input
                      type="checkbox"
                      name={fieldName}
                      alt={field.description}
                      onChange={updateFields}
                      checked={Boolean(logFields[fieldName])}
                    />
                    {Boolean(field.starred) && <span>⭐ </span>}
                    <span dangerouslySetInnerHTML={{ __html: field.description }}></span>
                  </label>
                </td>
                <td>
                  <VclTokensProvider
                    query={field.vcl}
                    render={(t) => (t ? <TokenLink {...t} /> : <code>{field.vcl}</code>)}
                  />
                </td>
                <td>{field.edgeCompute ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
