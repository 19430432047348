import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const SUPPORT_LEVEL_WEIGHT = {
  "fastly-product": "0",
  "fastly-oss-tier1": "1",
  "fastly-oss-tier2": "2",
}

export default ({ topic, render }) => {
  const gql = graphql`
    query {
      repos: githubData {
        data {
          organization {
            repositories {
              nodes {
                description
                name
                nameWithOwner
                pushedAt
                repositoryTopics {
                  nodes {
                    topic {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  const nodes = useStaticQuery(gql)
    .repos.data.organization.repositories.nodes.map(
      ({ name, nameWithOwner, description, pushedAt, repositoryTopics }) => ({
        name,
        nameWithOwner,
        description,
        pushedAt,
        topics: repositoryTopics.nodes.map((t) => t.topic.name),
      })
    )
    .filter((repo) => !topic || repo.topics.includes(topic))
    .sort((a, b) => {
      const a_str = SUPPORT_LEVEL_WEIGHT[a.topics.find((t) => t in SUPPORT_LEVEL_WEIGHT)] + a.name.toLowerCase()
      const b_str = SUPPORT_LEVEL_WEIGHT[b.topics.find((t) => t in SUPPORT_LEVEL_WEIGHT)] + b.name.toLowerCase()
      return a_str < b_str ? -1 : 1
    })

  return <>{render(nodes || [])}</>
}
