import React, { useState, useEffect, useRef } from "react"
import clsx from "clsx"
import { format } from "date-fns"

import Stream, { READY_STATES } from "~utils/stream"
import isClientSide from "~utils/is-client-side"
import copyToClipboard from "~utils/copy-to-clipboard"
import * as styles from "./sse-log-stream.module.css"

const TIME_GAP_SEPARATOR = 3000
const MAX_LOG_HISTORY = 500
const DEFAULT_HOST = "https://log-bin.fastly.dev/"
const TIME_FORMAT = "HH:mm:ss"

// Try to extract the POP name from the 'source' field
const parseSource = (str) => {
  const m = str.match(/\b([A-Z]{3})\b/)
  return m ? m[1].toUpperCase() : false
}

// Generate a random URL for the log bin (log bin app required this to be min 10 chars)
const randomUrl = () => DEFAULT_HOST + Math.random().toString(36).slice(2, 12).toUpperCase()

const LogStreamEntry = ({ isSep, evt }) => {
  const src = Boolean(evt.fields.source) && parseSource(evt.fields.source.value)
  const context = Boolean(evt.fields.context) && evt.fields.context.value

  return (
    <li className={clsx({ [styles.separator]: Boolean(isSep) })}>
      <span className={styles.timestamp}>{evt.timeString}</span>
      {src && (
        <span data-tip="Fastly POP location sending this log data" className={styles.source}>
          {src}
        </span>
      )}
      {context && (
        <span data-tip="The execution context that triggered this event" className={styles.context}>
          {context}
        </span>
      )}
      <span>{evt.message}</span>
    </li>
  )
}

const SSELogStream = ({ href, session, onReady }) => {
  const [thisHref] = useState(href ?? randomUrl())
  const [logs, setLogs] = useState([])
  const [autoScroll, setAutoScroll] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const containerEl = useRef()

  // Only on mount/unmount - connect/disconnect stream
  useEffect(() => {
    const stream = new Stream(thisHref)
    stream.on("log", (newEvent) => {
      if (session && (!newEvent.fields.session || String(newEvent.fields.session.value) !== String(session))) return
      newEvent.timeString = format(new Date(newEvent.time), TIME_FORMAT)
      const shouldScroll =
        !containerEl.current ||
        containerEl.current.scrollHeight - (containerEl.current.scrollTop + containerEl.current.offsetHeight) < 30
      setAutoScroll(shouldScroll)
      setLogs((prevLogs) => [...prevLogs, newEvent].slice(-MAX_LOG_HISTORY))
    })
    stream.on("stateChange", (newState) => {
      if (newState === READY_STATES.OPEN) {
        if (onReady) onReady()
        setIsConnected(true)
      } else {
        setIsConnected(false)
      }
    })
    stream.on("reconnect", () => {
      const timestamp = Date.now()
      const timeString = format(timestamp, TIME_FORMAT)
      const newEvent = {
        id: `reconnect-${timestamp}`,
        message: "[Streaming was paused while this page was inactive]",
        time: timestamp,
        timeString,
        fields: [],
      }
      setLogs((prevLogs) => {
        if (prevLogs.length === 0 || !prevLogs[prevLogs.length - 1].message.includes("Streaming was paused")) {
          return [...prevLogs, newEvent].slice(-MAX_LOG_HISTORY)
        } else {
          return prevLogs
        }
      })
    })
    stream.connect()
    return () => stream.close()
  }, [thisHref, onReady, session])

  // On every render, scroll the viewer if needed
  useEffect(() => {
    if (
      autoScroll &&
      containerEl.current &&
      containerEl.current.scrollTop + containerEl.current.offsetHeight < containerEl.current.scrollHeight
    ) {
      containerEl.current.scroll({ top: 9999999, left: 0, behavior: "smooth" })
    }
  })

  function copyCurl() {
    const cmd = `curl -X POST '${thisHref}' -d 'Hello!'`
    copyToClipboard(cmd)
  }

  return (
    <div className={styles.container}>
      {isClientSide() && (
        <>
          <div className={styles.header}>
            <span className={clsx({ [styles.heading]: true, [styles.connected]: isConnected })}>Log viewer</span>
            {thisHref !== href && thisHref && (
              <span
                className={styles.channelName}
                data-tip="Click to copy as a cURL command"
                onClick={() => copyCurl()}
              >
                {thisHref}
              </span>
            )}
          </div>
          {logs.length > 0 ? (
            <ol ref={containerEl}>
              {logs.map((evt, idx, allEvts) => {
                const isSep = idx > 0 && evt.time > allEvts[idx - 1].time + TIME_GAP_SEPARATOR
                return <LogStreamEntry isSep={isSep} evt={evt} key={evt.id} />
              })}
            </ol>
          ) : (
            <div className={styles.empty}>Awaiting log data...</div>
          )}
        </>
      )}
    </div>
  )
}

export default SSELogStream
