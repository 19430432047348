// extracted by mini-css-extract-plugin
export var arrow = "tooltip-module--arrow--b6d03";
export var bottom = "tooltip-module--bottom--e6c02";
export var code = "tooltip-module--code--37127";
export var definition = "tooltip-module--definition--3ef67";
export var inner = "tooltip-module--inner--30243";
export var left = "tooltip-module--left--abc24";
export var light = "tooltip-module--light--37b85";
export var right = "tooltip-module--right--06cc5";
export var tooltip = "tooltip-module--tooltip--31493";
export var top = "tooltip-module--top--a5b46";