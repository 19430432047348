import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default ({ language, render }) => {
  const gql = graphql`
    query {
      deps: allFiddleDependenciesJson {
        nodes {
          language
          name
          version
        }
      }
    }
  `

  const nodes = useStaticQuery(gql).deps.nodes.filter((n) => !language || n.language === language)

  return <>{render(nodes || null)}</>
}
