import "~assets/styles/global.css"
import "@fontsource-variable/inter"

function getLocalStorage() {
  try {
    // Because this throws in iframe in incognito mode
    return window.localStorage
  } catch {
    return undefined
  }
}

if (typeof window !== "undefined") {
  if (window.location.search.includes("fastly-devhub-editor") && getLocalStorage()) {
    getLocalStorage().setItem("fastly-devhub-editor", true)
  }
}
