export const PATH_PREFIX = process.env.GATSBY_PATH_PREFIX?.startsWith("/") ? process.env.GATSBY_PATH_PREFIX : ""
export const NON_GATSBY_LINK = /^((https?:)?\/\/|#|mailto:)/
export const ANCHOR_LINK = /^#/
export const WWW_LINK = /\/.+?\.fastly\.com\//
export const DOWNLOAD_LINK = /^\/?downloads\//

// Add prefix to a path if not present.
export const prefixPath = (path) => {
  if (
    !path ||
    path.startsWith(PATH_PREFIX) ||
    NON_GATSBY_LINK.test(path) ||
    ANCHOR_LINK.test(path) ||
    DOWNLOAD_LINK.test(path)
  ) {
    return path
  }
  return PATH_PREFIX + path
}

export const stripPrefix = (path) => (path && path.startsWith(PATH_PREFIX) ? path.slice(PATH_PREFIX.length) : path)

// Trim the prefix from a path, if present, and ensure it ends with a trailing slash.
export const normalizePath = (inp) => {
  // Path prefix is added by Gatsby at build time, and not reflected in the graphql query.
  // We need to remove it from the path to match the path in the query.
  let normalizedPath = stripPrefix(inp)
  // Ensure trailing slash.
  return normalizedPath.replace(/\/$/, "") + "/"
}
