import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const Provider = () => {
  const gql = graphql`
    query {
      starters: allSitePage(
        filter: { pageContext: { template: { eq: "starter-kit" } } }
        sort: { fields: [pageContext___title], order: ASC }
      ) {
        nodes {
          path
          context: pageContext {
            title
            summary
            language
            topics
          }
        }
      }
    }
  `
  return useStaticQuery(gql).starters.nodes.map(({ path, context }) => ({ path, ...context }))
}

export default ({ render }) => {
  const nodes = Provider()

  return <>{render(nodes || null)}</>
}
