import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const Provider = () => {
  const gql = graphql`
    query {
      tutorials: allSitePage(
        filter: { pageContext: { template: { eq: "tutorial" } } }
        sort: { fields: [pageContext___title], order: ASC }
      ) {
        nodes {
          path
          context: pageContext {
            title
            externalUrl
            summary
            platform
            topics
          }
        }
      }
    }
  `
  return useStaticQuery(gql).tutorials.nodes.map(({ path, context }) => ({
    path: path,
    ...context,
  }))
}

export default ({ render }) => {
  const nodes = Provider()

  return <>{render(nodes || null)}</>
}
