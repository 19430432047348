import React from "react"
import { Grid } from "~components/documentation/grid/grid"
import Link from "~components/link/link"
import useNavigationTree from "~hooks/navigation-tree"
import { stripPrefix } from "~utils/path"

import * as styles from "./back-next.module.css"

export default ({ path }) => {
  // Find the parent path.
  const parentPath = (path.replace(/\/$/, "").match(/(.+)\//) || [])[0]
  if (!parentPath) {
    return null
  }

  // Find the sibling pages.
  const { navTree, childrenOfPath } = useNavigationTree(parentPath)
  const siblings = childrenOfPath(navTree, parentPath)

  // Find the previous and next pages.
  const currentPageIndex = siblings.findIndex((p) => stripPrefix(p.path) === stripPrefix(path))
  const previousPage = siblings[currentPageIndex - 1]
  const nextPage = siblings[currentPageIndex + 1]

  return (
    (previousPage || nextPage) && (
      <Grid gridTemplateColumns={{ sm: 1, md: 2 }} gap={"2xl"} marginBlockStart="2xl">
        {previousPage ? (
          <Link to={previousPage.path} className={styles.back}>
            « Back: {previousPage.title}
          </Link>
        ) : (
          <div></div>
        )}
        {nextPage ? (
          <Link to={nextPage.path} className={styles.next}>
            Next: {nextPage.title} »
          </Link>
        ) : (
          <div></div>
        )}
      </Grid>
    )
  )
}
