// extracted by mini-css-extract-plugin
export var deleteEndpoint = "api-index-module--deleteEndpoint--30b78";
export var deprecated = "api-index-module--deprecated--92553";
export var endpointPath = "api-index-module--endpointPath--87b11";
export var endpointTitle = "api-index-module--endpointTitle--3e5d9";
export var endpointTitleColumn = "api-index-module--endpointTitleColumn--b40fa";
export var endpointUrl = "api-index-module--endpointUrl--45247";
export var getEndpoint = "api-index-module--getEndpoint--c0e6e";
export var methodLabel = "api-index-module--methodLabel--3ac11";
export var patchEndpoint = "api-index-module--patchEndpoint--60649";
export var postEndpoint = "api-index-module--postEndpoint--17ae2";
export var purgeEndpoint = "api-index-module--purgeEndpoint--42eae";
export var putEndpoint = "api-index-module--putEndpoint--86fcc";
export var referenceLink = "api-index-module--referenceLink--fdb74";