import React from "react"
import Icon from "~components/icon/icon"
import * as styles from "./calendar.module.css"

const categoryFlyline = (cat) => {
  if (cat === "organizer") return <span className={`${styles.fly} ${styles.organizer}`}>⬥ Fastly event</span>
  if (cat === "host") return <span className={`${styles.fly} ${styles.host}`}>Hosted by Fastly</span>
  if (cat === "speaker") return <span className={`${styles.fly} ${styles.host}`}>Fastly speaker</span>
  return null
}

const Calendar = ({ nodes }) => {
  if (!nodes) {
    return <p>We have no events scheduled at the moment.</p>
  }

  return (
    <>
      <div className="tableWrapper">
        <table>
          <tbody>
            {nodes.map((evt) => (
              <tr key={evt.href}>
                <td>
                  {categoryFlyline(evt.category)}
                  <strong>
                    <a href={evt.href}>{evt.title}</a>
                  </strong>
                </td>
                <td>
                  <nobr>{evt.dateTimeStr}</nobr>
                  <br />
                  <span className={styles.location}>{evt.location}</span>
                </td>
                <td className={styles.icons}>
                  {!!evt.isStreamed && <Icon id="video" tip="Can be attended remotely" />}
                  {evt.cost > 0 && <Icon id="price" tip={"In person attendance from " + evt.priceStr} />}
                </td>
                <td>{evt.teaser}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default Calendar
