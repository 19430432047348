import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const Provider = ({ language, expressly } = {}) => {
  const gql = graphql`
    query {
      examples: allExamplesJson {
        nodes {
          path: gatsbyPath(filePath: "/solutions/examples/{examplesJson.fields__slug}")
          title
          summary
          tags
          implementations {
            vcl {
              fiddleID
              tokens
            }
            rust {
              fiddleID
            }
            javascript {
              fiddleID
              expressly
            }
            go {
              fiddleID
            }
          }
          topics
          featured
        }
      }
    }
  `

  return useStaticQuery(gql)
    .examples.nodes.filter((n) => n.summary != null)
    .filter((n) => !language || n.implementations[language] !== null)
    .filter((n) => (language === "javascript" && expressly ? n.implementations.javascript.expressly : true))
}

export default ({ render, language, expressly }) => {
  const nodes = Provider({ language, expressly })

  return <>{render(nodes || null)}</>
}
