import React, { useState } from "react"
import { marked } from "marked"

import Icon from "~components/icon/icon"

import * as styles from "./decision-tree.module.css"

const md = (text) =>
  marked
    .parse(text)
    .trim()
    .replace(/^<p>(.*)<\/p>$/, "$1")

export default ({ title, summary, nodes }) => {
  const [decisionPath, setDecisionPath] = useState(null)

  const addPathDecision = (src, dst) => setDecisionPath((prev) => [...(prev || []), [src, dst]])
  const resetPath = (idx) => setDecisionPath((prev) => prev.slice(0, idx))
  const startWizard = () => setDecisionPath([])

  if (decisionPath === null) {
    return (
      <div className={styles.container}>
        <div className={styles.entry}>
          <Icon id="decisionTree" />
          <div className={styles.content}>
            <div className={styles.heading}>{title}</div>
            <p>{summary}</p>
          </div>
          <button className={styles.button} onClick={startWizard}>
            Start
          </button>
        </div>
      </div>
    )
  }

  const curState = decisionPath.length > 0 ? decisionPath[decisionPath.length - 1][1] : "root"
  const curStateNode = nodes[curState] ?? {
    title: "⚠️ Oh dear, this decision tree does not contain a node called '" + curState + "'",
  }

  return (
    <div className={styles.container}>
      {decisionPath.length > 0 && (
        <ol className={styles.priorDecisions}>
          {decisionPath.map(([state, dest], idx) => (
            <li key={state}>
              <span className={styles.heading} dangerouslySetInnerHTML={{ __html: md(nodes[state].title) }} />
              <span className={styles.decision} onClick={() => resetPath(idx)} data-tip="Click to change">
                {nodes[state].options.find((o) => o.dest === dest).label}
              </span>
            </li>
          ))}
        </ol>
      )}
      <div className={styles.entry}>
        {!Boolean(curStateNode.options) && <Icon id="recommendation" />}
        <div className={styles.content}>
          <div className={styles.heading} dangerouslySetInnerHTML={{ __html: md(curStateNode.title) }} />
          <p dangerouslySetInnerHTML={{ __html: md(curStateNode.summary || "") }} />
          {Boolean(curStateNode.options) && (
            <ul className={styles.options}>
              {curStateNode.options.map((o) => (
                <li key={o.dest}>
                  <button className={styles.button} onClick={() => addPathDecision(curState, o.dest)}>
                    {o.label}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}
