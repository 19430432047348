import React from "react"
import Link from "~components/link/link"

const TokenLink = ({ path, name }) => (
  <code>
    <Link to={path}>{name}</Link>
  </code>
)

export default TokenLink
